import React from "react";

// Components
import { SEO } from 'components/core/seo';
import { Team } from "components/contact";
import { useTranslation } from "react-i18next";


export function ContactPage(){
    const {t} = useTranslation("common");

    return <div className="px-6">
                <SEO title={t("contact.title")} description={t("contact.description")} />
                <Team />
            </div>
}