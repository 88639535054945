import React from "react";

// Components
import { SEO } from 'components/core/seo';
import { FAQ } from "components/faq";
import { useTranslation } from "react-i18next";

// Hooks


export function FAQPage(){
    const {t} = useTranslation("common");
    return <div className="px-6">
                <SEO title={t("faq.title")} description={t("faq.description")} />
                <FAQ />
            </div>
}