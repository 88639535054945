import React from "react";

// Components
import { SEO } from 'components/core/seo';
import { Resources } from "components/resources";
import { Heading } from "components/core/typo";

// Hooks
import { useTranslation } from "react-i18next";


export function ResourcesPage(){

    const {t} = useTranslation("common");

    return <div className="px-6">
                <SEO title={t("resources.title")} description={t("resources.description")} />
                <Heading title={t("resources.title")} />
                <Resources />
            </div>
}