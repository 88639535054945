import React, { useReducer, useMemo, createContext, useEffect } from "react";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useLocation } from "react-router-dom";

function toggleItem(array, value, key = "label") {
    if (!array) return [value];
    const filteredArray = array.filter((d) => d[key] !== value[key]);
    return filteredArray.length === array.length
        ? [...array, value]
        : filteredArray;
}

function filterReducer(state, { type, value }) {
    const currentState = { ...state };

    switch (type) {
        case "toogle-school-subject":
            currentState.schoolSubjects = toggleItem(
                currentState.schoolSubjects,
                value,
                "slug"
            );
            return currentState;

        case "update-option-value":
            currentState.options = currentState.options.map((d) =>
                d.label === value.label ? value : d
            );
            return currentState;

        case "set-student-session-length":
            currentState.studentSessionLength = {
                ...currentState.studentSessionLength,
                [value.student.ni]: value.sessionLength,
            };
            return currentState;

        case "set-student-school-subjects":
            currentState.studentSchoolSubjects = {
                ...currentState.studentSchoolSubjects,
                [value.student.ni]: value.schoolSubjects,
            };
            return currentState;

        case "set-student-note":
            currentState.studentNotes = {
                ...currentState.studentNotes,
                [value.student.ni]: value.studentNotes,
            };
            return currentState;

        case "toggle-option":
            currentState.options = toggleItem(
                currentState.options,
                value,
                "label"
            );
            return currentState;

        case "toggle-student":
            currentState.students = toggleItem(
                currentState.students,
                value,
                "ni"
            );
            return currentState;

        case "reset":
            return {};

        default:
            return { ...state, [type]: value };
    }
}

function updateWithDefaultFields(val, defaultFields) {
    if (!defaultFields) return val;
    return { ...val, ...defaultFields };
}
const AddStudySessionContext = createContext({});

const AddStudySessionContextProvider = ({
    children,
    doNotStore,
    storageKey,
    defaultState,
    defaultFields,
}) => {
    const location = useLocation();
    const [cachedFilters, setCachedFilters] = useLocalStorage(
        storageKey || location.pathname + "_value"
    );

    const parsedDefaultFilters = useMemo(() => {
        if (!cachedFilters || doNotStore) return;
        // Date fields must be parsed
        return cachedFilters;
    }, []);

    const [value, updateStudySession] = useReducer(
        filterReducer,
        updateWithDefaultFields(
            parsedDefaultFilters || defaultState || {},
            defaultFields
        )
    );

    const isValid = useMemo(() => {
        if (!(value.pairing || value.students)) return false;
        if (value.pairing) {
            return (
                value.sessionFormat &&
                value.date &&
                value.sessionLength &&
                value.schoolSubjects &&
                value.schoolSubjects.length > 0
            );
        } else {
            const validStudents = value.students
                .map((student) => {
                    const subjects =
                        (value.studentSchoolSubjects || {})[student.ni] ||
                        (value && value.schoolSubjects) ||
                        [];
                    const sessionLength =
                        (value.studentSessionLength || {})[student.ni] ||
                        value.sessionLength;
                    return subjects.length > 0 && sessionLength;
                })
                .reduce((acc, d) => acc && d, true);

            return value.date && validStudents;
        }
    }, [value]);

    useEffect(() => {
        if (!doNotStore) setCachedFilters(value);
    }, [value]);

    return (
        <AddStudySessionContext.Provider
            value={{ studySession: value, updateStudySession, isValid }}
        >
            {children}
        </AddStudySessionContext.Provider>
    );
};

export { AddStudySessionContext, AddStudySessionContextProvider };
