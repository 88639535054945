import React from 'react';

// Utils
import classnames from 'classnames';

// Components
import { WifiIcon, UserGroupIcon } from '@heroicons/react/24/outline';

// Hooks
import { useMemo } from 'react';

const icon = {
  "en-ligne": WifiIcon,
  "en-personne": UserGroupIcon
}

export function FormatCell({code, name}){
  const Icon = icon[code];
  return <div className={classnames("rounded-md p-2 md:flex items-center")}>
          <div className={classnames("flex-1")}>
            <div className="flex items-center justify-between">
            <p className="text-gray-800 text-lg font-medium">
              {Icon && <Icon className="h-6 w-6 mr-2 inline-flex" />}
              <span>{name}</span>
            </p>
            </div>
          </div>
         </div> 
}

export function SelectSessionFormatForm({ formats, value, setValue }){
    const acceptedFormats = useMemo(()=>formats?.filter(f=>!!icon[f.code]), [formats]);
    return <div className="grid gris-cols-1 md:grid-cols-2 gap-3 md:gap-3">
              {acceptedFormats.map(d=>
                <div key={d.code} 
                  className={classnames("rounded-md  shadow-sm col-span-1 p-2 py-2.5 flex items-center cursor-pointer",
                    value && value.code===d.code? "text-white ring-[2pt] ring-orange-500 bg-orange-50 ": "ring-1 ring-gray-300")}
                   onClick={()=>setValue(d)}>
                  <div className="w-6 text-center " >
                    <input type="radio" className="text-orange-500 " code={d.code} name={"format"} checked={(value && value.code===d.code) || false} readOnly />
                  </div>
                <div className="flex-1 ml-3">
                  <FormatCell {...d}/>
                </div>
              </div>)}
           </div>
  
  }
   