import React from "react";

// Context
import { AddStudySessionContextProvider } from "contexts/add-study-session";

// Components
import { AddStudySessionPageWithContext } from "components/add-study-session/add-multiple-sessions";

export function AddTutoratPrivePage(){
  return <AddStudySessionContextProvider doNotStore defaultState={{sessionLength: 60}}>
            <AddStudySessionPageWithContext sessionType={"tutorat-prive"} redirectOnSuccess="/ajouter-tutorat-prive/confirmation"/>  
        </AddStudySessionContextProvider>
}
