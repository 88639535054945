import React from "react";
// Context
// Components
import { Heading } from "components/core/typo";
import { RocketLaunchIcon } from "@heroicons/react/24/outline";
import { SEO } from 'components/core/seo';

// Hooks
import { useTranslation } from "react-i18next";
import { Button } from "components/core/button";

/*
  Add a study session when logged.
*/
export function CompletedTutoratPage({to}) {
  const { t } = useTranslation("add-study-session");
  return <div className="px-6">
    <SEO title={t("confirmation-aad.title")}/>
    <Heading Icon={RocketLaunchIcon} title={t("confirmation-aad.title")} description={t("confirmation-aad.description")} />
    <Button to={to} color="active" size="md" className="mt-6">{t("confirmation-aad.button")}</Button>
  </div>
}
