import React, { useEffect } from 'react';

// Hooks
import { useMemo, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {  Heading } from 'components/core/typo';

// Context
import { SelectOptionsFormFullWidth } from 'components/select/select-options';
import { BoltSlashIcon, BookOpenIcon, ClockIcon } from '@heroicons/react/24/outline';
import { AddStudySessionContext } from 'contexts/add-study-session';
import { Tooltip } from 'components/core/tooltip';

export function SelectedOptionsList({options}){
    const { t } = useTranslation("add-study-session");
    if (!options) return null;
    return <div className='flex relative'>{options.map(({label, Icon, comments})=>
                <Tooltip key={label}  content={comments || t("select-options.no-comment")} delay={50}>
                    <span><Icon className="h-7 w-7 inline-flex mr-2 text-red-600 rounded-md p-1 bg-red-100"/></span>
                </Tooltip>)}
            </div>
}
export function SelectOptions({student}){
    const { t } = useTranslation("add-study-session");
    const options = useMemo(()=>[
        {
            title: t("options.manque-concentration.title"),
            description: t("options.manque-concentration.description"),
            placeholder: t("options.manque-concentration.placeholder"),
            label: "manque-de-concentration-tutorat",
            Icon: BoltSlashIcon
        },
        {
            title: t("options.manque-materiel.title"),
            description: t("options.manque-materiel.description"),
            placeholder: t("options.manque-materiel.placeholder"),
            label: "manque-de-materiel-tutorat",
            Icon: BookOpenIcon
        },
        {
            title: t("options.retard.title"),
            description: t("options.retard.description"),
            placeholder: t("options.retard.placeholder"),
            label: "retard-tutorat",
            Icon: ClockIcon
        }
    ], []);

    const { studySession, updateStudySession } = useContext(AddStudySessionContext);

    const [selectedOptions, setSelectedOptions] = useState((studySession.studentNotes && studySession.studentNotes[student.ni]) || []);

    useEffect(()=> {
        updateStudySession({type: 'set-student-note', value: {studentNotes : selectedOptions, student}})
    },[selectedOptions])

    const handleNoteChange = (value) => {
        selectedOptions.map(option => {
            if (option.label == value.label){
                option.comments = value.comments;
                return;
            }
        });
    }


    function toggleOption(value){
        const filteredOptions = selectedOptions?.filter(v=>v?.label!==value?.label)
        if (selectedOptions.length !== filteredOptions.length){
            return setSelectedOptions(filteredOptions)
        }
        return setSelectedOptions([...selectedOptions, value])
    }
    return  <div>
                <Heading mediumTitle={t("select-options.title")}
                        description={t("select-options.description")}
                />
                <SelectOptionsFormFullWidth options={options}
                        values={selectedOptions}
                        onChange={handleNoteChange}
                        onClick={value=>toggleOption(value)}/>
            </div>
}
